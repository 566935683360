<template>

  <v-container v-if="isBanned">
    <v-alert
      v-for="ban in bans"
      :key="ban.id"
      border="left"
      type="error"
      text
    >
<!--      <pre>{{ 'Your account has been banned.' + (ban.note ? ` Note: ${ban.note}` : '') }}</pre>-->
      <span>{{ 'Your account has been banned.' + (ban.note ? ` Note: ${ban.note}` : '') }}</span>
<!--      <span v-html="reservationItem.descriptionText"></span>-->
    </v-alert>
  </v-container>

  <v-container v-else fluid>
    <v-card>
      <v-toolbar tile>
        <v-tabs v-model="tab" color="primary">
          <v-tab>Calendar</v-tab>
          <v-tab>My reservations</v-tab>
        </v-tabs>

        <v-spacer></v-spacer>

        <v-btn-toggle v-if="tab === 0" v-model="type" mandatory dense tile class="d-none d-md-flex d-lg-flex">
          <v-btn text value="day">Day</v-btn>
          <v-btn text value="week">Week</v-btn>
        </v-btn-toggle>

        <v-item-group v-if="tab === 0" class="ml-2 d-none d-md-flex d-lg-flex" dense tile>
          <v-btn tile text @click="prev"><v-icon small>mdi-chevron-left</v-icon></v-btn>
          <v-btn tile text @click="setToday">Today</v-btn>
          <v-btn tile text @click="next"><v-icon small>mdi-chevron-right</v-icon></v-btn>
        </v-item-group>
      </v-toolbar>

      <v-card-text v-if="tab === 0">

        <v-row class="d-none d-md-flex d-lg-flex">
          <v-col>
            <v-select
              style="width: 250px"
              color="primary"
              class="mr-4"
              hide-details
              :items="reservationItems"
              :item-text="(item) => `${item.name} [${item.roomNumber}]`"
              :item-value="(item) => item.id"
              return-object
              label="Reservation item"
              dense
              solo
              v-model="reservationItem"
              @change="setEvents"
            >
            </v-select>
          </v-col>

          <v-col align="right">
            <v-btn @click="newReservationDialog=true" color="primary" class="mr-4">
              New reservation
            </v-btn>
          </v-col>
        </v-row>

        <v-row class="d-flex d-md-none">
          <v-col cols="12" md="4">
            <v-select
              v-model="reservationItem"
              :items="reservationItems"
              :item-text="(item) => `${item.name} [${item.roomNumber}]`"
              :item-value="(item) => item.id"
              label="Reservation item"
              color="primary"
              hide-details
              dense
              solo
              return-object
              @change="setEvents"
            >
            </v-select>
          </v-col>

          <v-col cols="12" md="4">
            <v-text-field
              hide-details
              dense
              solo
              :clearable="false"
              v-model="date"
              label="Date" type="date"
            >
            </v-text-field>
          </v-col>

          <v-col cols="12" md="4">
            <v-btn width="100%" @click="newReservationDialog=true" color="primary">
              New reservation
            </v-btn>
          </v-col>

        </v-row>

        <v-row v-if="reservationItem">
          <v-col v-if="reservationItem.descriptionText">
            <v-alert
              border="left"
              type="info"
              text
            >
              <span v-html="reservationItem.descriptionText"></span>
            </v-alert>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="12">
            <v-calendar
              v-model="date"
              event-overlap-mode="column"
              :first-time="(availabilityStart - 1) * 60"
              :interval-count="availabilityEnd - availabilityStart + 2"
              interval-height="40"
              :interval-format="(interval) => interval.time"
              ref="calendar"
              color="primary"
              :events="events"
              :event-color="getEventColor"
              :type="type"
              @click:more="viewDay"
              @click:date="viewDay"
              @click:day="viewDay"
            >
              <template v-slot:day-label-header="{ date }">
                {{ date }}
              </template>
<!--              <template v-slot:event="{ event }">                -->
<!--                <v-row style="font-size: medium" class="ml-2 mt-1">-->
<!--                  {{ `${event.name}, ${event.start.toTimeString().slice(0, 5)} - ${event.end.toTimeString().slice(0, 5)}`}}-->
<!--                </v-row>                -->
<!--              </template>-->

              <template v-slot:event="{ event }">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-row v-if="event.end - event.start > 3600000" style="font-size: medium" class="ml-2 mt-1" v-bind="attrs" v-on="on">
                      {{ `${event.name}` }}
                      <br>
                      {{ `${event.start.toTimeString().slice(0, 5)} - ${event.end.toTimeString().slice(0, 5)}`}}
                    </v-row>
                    <v-row v-else style="font-size: medium" class="ml-2 mt-1" v-bind="attrs" v-on="on">
                      {{ `${event.name}, ${event.start.toTimeString().slice(0, 5)} - ${event.end.toTimeString().slice(0, 5)}`}}
                    </v-row>
                  </template>
                  <span>{{ `${event.name}, ${event.start.toTimeString().slice(0, 5)} - ${event.end.toTimeString().slice(0, 5)}`}}</span>
                </v-tooltip>
              </template>

            </v-calendar>
          </v-col>
        </v-row>

        <v-row>
          <v-col align="right">
            <v-btn @click="newReservationDialog=true" fab color="primary" class="mr-4">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text v-if="tab === 1">
        <v-data-table
          :headers="headers"
          :items="reservations"
          :loading="loading"
          loading-text="Loading... Please wait"
        >
          <template v-slot:item.reservationItem="{ item }">
            {{ item.reservationItem.name }}
          </template>
          <template v-slot:item.note="{ item }">
            <small>{{ item.note }}</small>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon class="ml-3" color="red" @click="openDeleteReservationDialog(item)">
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="newReservationDialog"
      persistent
      max-width="600px"
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-card-title>
          <span class="headline">New reservation</span>
        </v-card-title>
        <v-card-text>
          <v-container>

            <v-select
              v-model="reservationItem"
              :items="reservationItems"
              :item-text="(item) => item.name"
              :item-value="(item) => item.id"
              return-object
              label="Item"
            >
            </v-select>

            <v-text-field v-model="date" label="Date" type="date"></v-text-field>
            <v-select
              v-model="start"
              :items="Array.from(new Array(availabilityEnd-availabilityStart), (val, index) => (index+availabilityStart).toString().padStart(2, '0') + ':00')"
              label="Start">
            </v-select>
            <v-select
              v-model="end"
              label="End"
              :items="Array.from(new Array(availabilityEnd-availabilityStart), (val, index) => (index+availabilityStart+1).toString().padStart(2, '0') + ':00')"
            >
            </v-select>
            <v-text-field v-model="note" label="Note"></v-text-field>

          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="newReservationDialog = false">Close</v-btn>
          <v-btn color="blue darken-1" text @click="createUserReservation">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="deleteReservationDialog"
      max-width="500px"
      transition="dialog-bottom-transition"
    >
      <v-card tile>
        <v-card-title class="headline">Are you sure you want to delete this item?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="deleteReservationDialog = false">Cancel</v-btn>
          <v-btn color="red darken-1" text @click="deleteUserReservation">Yes</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  data () {
    return {
      isBanned: false,
      bans: [],
      events: [],
      reservation: null,
      reservations: [],
      reservationItem: null,
      reservationItems: [],
      availabilityStart: 8,
      availabilityEnd: 22,
      tab: null,
      date: new Date().toISOString().slice(0, 10),
      type: 'day',
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      start: '',
      end: '',
      note: '',
      colors: ['blue', 'blue darken-1', 'blue darken-2', 'blue darken-3', 'blue darken-4'],
      newReservationDialog: false,
      deleteReservationDialog: false,
      item: null,
      loading: true,
      search: '',
      headers: [
        { text: 'ITEM', value: 'reservationItem' },
        { text: 'START', value: 'start' },
        { text: 'END', value: 'end' },
        { text: 'NOTE', value: 'note' },
        { text: 'ACTIONS', value: 'actions', sortable: false }
      ]
    }
  },

  async mounted () {
    document.title = 'Reservations | Masarka Student Club'
    this.loading = true
    this.bans = await this.$store.dispatch('account/fetchUserBans')
    if (this.bans.length > 0) {
      this.isBanned = true
    }
    this.reservationItems = await this.$store.dispatch('account/fetchActiveReservationItems')
    this.reservations = await this.$store.dispatch('account/fetchUserReservations')
    this.loading = false
  },

  methods: {
    setToday () {
      this.date = ''
      this.type = 'day'
    },
    viewDay ({ date }) {
      this.date = date
      this.type = 'day'
    },
    getEventColor (event) {
      return event.color
    },
    prev () {
      this.$refs.calendar.prev()
    },
    next () {
      this.$refs.calendar.next()
    },
    rnd (a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a
    },

    async setEvents () {
      this.availabilityStart = this.reservationItem.availabilityStart
      this.availabilityEnd = this.reservationItem.availabilityEnd
      this.events = await this.$store.dispatch('account/fetchReservationsByReservationItem', this.reservationItem.id)
      for (let i = 0; i < this.events.length; i++) {
        this.events[i].name = this.events[i].user.name + ' ' + this.events[i].user.surname
        this.events[i].start = new Date(this.events[i].start)
        this.events[i].end = new Date(this.events[i].end)
        // this.events[i].color = this.colors[this.rnd(0, this.colors.length - 1)]
        this.events[i].color = 'blue darken-1'
        this.events[i].timed = true
      }
    },

    async fetchUserReservations () {
      this.loading = true
      this.reservations = await this.$store.dispatch('account/fetchUserReservations')
      this.loading = false
    },

    async createUserReservation () {
      this.newReservationDialog = false
      await this.$store.dispatch('account/createUserReservation', {
        reservationItem: this.reservationItem,
        start: this.date + 'T' + this.start + ':00',
        end: this.date + 'T' + this.end + ':00',
        note: this.note
      })
      await this.setEvents()
      await this.fetchUserReservations()
    },

    async deleteUserReservation () {
      await this.$store.dispatch('account/deleteUserReservation', this.reservation.id)
      this.deleteReservationDialog = false
      await this.setEvents()
      await this.fetchUserReservations()
    },

    openDeleteReservationDialog (item) {
      this.reservation = item
      this.deleteReservationDialog = true
    }
  }
}
</script>
